import React from "react";
import SLGpage from "../components/SLG/index";
import { PageSEO } from "../atoms/SEO";
export default function slg({ location }) {
  return (
    <div>
      <PageSEO
        path={location.pathname}
        title={"SaaSPay's B2B Buy-Now-Pay-Later for SaaS and Cloud expenses"}
        description={
          "Forget your sales rep's manual follow up for revenue. Easily increase annual subscription sales and retention with SaaSPay's B2B Buy-Now-Pay-Later solution."
        }
      />
      <SLGpage />
    </div>
  );
}
