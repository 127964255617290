import React from "react";

import Simplifiedsaspay from "../../assets/images/Frame 1171280575.png";
import Lacalise from "./Localise";

export default function Navigate() {
  return (
    <>
      <div className="  sm:py-32 bg-white">
        <div className="w-11/12 2xl:max-w-[1170px] 3xl:max-w-[1500px]  mx-auto ">
          <h1 className="f-f-b-g text-4xl sm:text-7xl text-black text-center leading-[56px] ">
            Navigate the Ultimate <br /> Sales Journey
          </h1>
          <h2 className=" text-primary-light text-center f-f-m text-lg mt-3 ">
            Simplified. Streamlined. Centralised.
          </h2>
          <img
            src={Simplifiedsaspay}
            className="w-full h-auto xl:h-[520px] mt-[72px] "
            alt=""
          />
        </div>
        <Lacalise />
      </div>
      <div className=" w-11/12 2xl:max-w-[1170px] 3xl:max-w-[1500px] mx-auto">
        <div className=" mt-[39px] liese-bg p-[32px]  h-auto ">
          <div className="grid grid-cols-12  gap-4 sm:gap-8 items-center">
            <div className=" col-span-12 xl:col-span-9">
              <h2
                style={{
                  fontSize: 32,
                }}
                className="f-f-b-g text-[20px] sm:text-5xl md:text-7xl md:leading-[56px] text-white  text-center xl:text-left "
              >
                Maximize annual subscription sales with one-click BNPL
              </h2>
              <h3
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                }}
                className="f-f-b-g text-[20px] sm:text-5xl md:text-7xl md:leading-[56px] text-white  text-center xl:text-left "
              >
                Empower your customers with flexible repayment options and
                unlock unprecedented growth.
              </h3>
            </div>
            <div className="col-span-12 xl:col-span-3 text-center xl:text-right ">
              <button
                className=" w-[205px] h-[64px]  rounded-[48px] bg-white text-green-light text-lg f-f-b "
                onclick={() =>
                  window.open("https://form.jotform.com/240982279984475")
                }
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
