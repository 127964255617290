import React from "react";

import Navbar from "../../organisms/Layout/Navbar";
import Hero from "./Hero";
import Revenue from "./Revenue";
import Navigate from "./Navigate";
import Footer from "../../organisms/Layout/Footer";
import Faq from "../../molecules/Faq";
import Insights from "./insights";

export const FaqData = [
  {
    heading: "How can I increase SaaS sales effectively?",
    content:
      "To increase SaaS sales effectively, you need a robust sales tool like SaaSPay. Our solution streamlines the sales process, helping you sell smart, fast-track deals, and eliminate payment follow-ups, ultimately boosting revenue and customer retention.",
  },
  {
    heading: "How can SaaS sales tools be effective in closing SaaS deals?",
    content:
      "Multi-currency transactions on AWS typically involve the following steps: Currency Selection, Currency Conversion, Billing and Invoicing, Payment Processing, Account Management. Overall, multi-currency transactions on AWS streamline the payment process for users operating in different regions, enabling them to leverage AWS services efficiently without concerns about currency compatibility.",
  },
  {
    heading:
      "What strategies work better for B2B SaaS solutions in the current market?",
    content:
      "Multi-currency on AWS supports a range of currencies to accommodate users worldwide. Specific supported currencies can be checked with AWS documentation or account settings.",
  },
  {
    heading:
      "What are the key considerations when selling SaaS to B2B Customers?",
    content:
      "No, multi-currency on AWS is not limited to specific currency pairs like INR to USD. It supports a variety of currencies, enabling users to conduct transactions in their preferred currency.",
  },
  {
    heading: "What are effective strategies for retaining B2B SaaS customers?",
    content:
      "There may be specific transaction limits associated with multi-currency usage on AWS, depending on the user's account type, region, and other factors. Users should refer to AWS documentation or account settings for information on transaction limits.",
  },
  {
    heading: "How can SaaS sales tools help with customer retention?",
    content:
      "There may be specific transaction limits associated with multi-currency usage on AWS, depending on the user's account type, region, and other factors. Users should refer to AWS documentation or account settings for information on transaction limits.",
  },
];

export default function index() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Revenue />
      <Navigate />
      <div className="xl:pt-[80px] ">
        <Insights />
      </div>
      <div className="pt-[70px] xl:pt-[100px] ">
        <Faq data={FaqData} />
      </div>
      <Footer />
    </div>
  );
}
