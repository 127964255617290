import React, { useState } from "react";
import play from "../../assets/images/Playbutton.svg";
import Seller from "./Seller";
import Lottie from "lottie-react";
import Heroanimation from "./SaaSPay_2nd Lottie.json";
import Modal from "../../atoms/Modal/secondary";
import Close from "../../images/buyer/about/cross.svg";

export default function Hero() {
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-[#E1F8FF] pb-[44px] pt-44 ">
      <Modal
        className={"!z-[100]"}
        width={""}
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div className="flex w-[70vw] h-[85vh] flex-col">
          <div className="flex w-full h-[5%] justify-end pr-2 pt-2 bg-[#535151]">
            <img
              className={"h-[24px]"}
              alt={"SecondaryCTAIcon"}
              src={Close}
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="flex rounded w-full h-[95%] ">
            <iframe
              src={
                showModal
                  ? "https://saaspay.vidico.com/share/93416220131248093534/86df5324-a2ac-40c0-a77e-a13959697322"
                  : ""
              }
              width={"100%"}
              height={"100%"}
            ></iframe>
          </div>
        </div>
      </Modal>
      <div className="w-11/12 2xl:max-w-[1208px] 3xl:max-w-[1500px] mx-auto">
        <h1 className="f-f-b-g text-5xl sm:text-8xl text-center 2xl:text-[64px] text-black md:leading-[72px]">
          Solve for Revenue Realisation,
          <br />
          Retention and Annual Subscription Sales
        </h1>
        <p className="f-f-m text-[20px] text-primary-light mt-4 text-center">
          Simplify Payments for Customers Buying with AWS & ISVs
        </p>
        <div className="text-center">
          <div className="sm:inline-flex items-center sm:space-x-[32px] mt-10">
            <button
              className="w-full sm:w-[251px] h-[52px] sm:h-[64px] rounded-[48px] bg-primary f-f-b text-lg text-white"
              onclick={() =>
                window.open(
                  "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04"
                )
              }
            >
              Book a Discovery call
            </button>
            <ul
              className="inline-flex items-center space-x-3 mt-3 sm:mt-0"
              onClick={() => setShowModal(true)}
            >
              <li>
                <img src={play} className="w-7 h-7 " alt="" />
              </li>
              <li className="f-f-b text-lg text-primary">Learn more</li>
            </ul>
          </div>
        </div>
        <Seller />
        <div
          className="w-full h-auto mt-[48px]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Lottie
            animationData={Heroanimation}
            loop={isHovered}
            autoplay={isHovered}
          />
        </div>
      </div>
    </div>
  );
}
