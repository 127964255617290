import React, { useState } from "react";

import empty from "../../assets/images/empty-wallet.png";
import emptyhvr from "../../assets/images/empty-wallet-hover.svg";
import coin from "../../assets/images/coin.svg";
import coinhvr from "../../assets/images/coin-hover.svg";
import send from "../../assets/images/send-2.svg";
import sendhvr from "../../assets/images/send-2-hover.svg";
import localsimage from "../../assets/images/localsimage.svg";
import localsimage2 from "../../assets/images/localsimage2.svg";
import localsimage3 from "../../assets/images/localsimage3.svg";

export default function Localise() {
  const [selectedCard, setSelectedCard] = useState(0);

  const cardsData = [
    {
      src: empty,
      srcHover: emptyhvr,
      heading: "Streamlining Order Dispatch to Your Customers",
      content: "Raise an invoice in INR using SaaSPay",
      buttontext: "Step 1",
      cardimg: localsimage,
    },
    {
      src: send,
      srcHover: sendhvr,
      heading: "Effortless Bulk Upload of Customer Details",
      content: "Hassle-free INR payment completed by the buyer",
      buttontext: "Step 2",
      cardimg: localsimage2,
    },
    {
      src: coin,
      srcHover: coinhvr,
      heading: "Centralized Transaction Monitoring for Simplified Review",
      content: "You realize revenue in USD facilitated by our Forex APIs",
      buttontext: "Step 3",
      cardimg: localsimage3,
    },
  ];

  return (
    <div className="w-11/12 2xl:max-w-[1240px] mx-auto ">
      <div className="mt-[80px]">
        <div className="grid grid-cols-12 md:mt-[104px] gap-4 sm:gap-8">
          {cardsData.map((card, index) => (
            <div
              className={`col-span-12 md:col-span-6 xl:col-span-4`}
              key={index}
            >
              <div
                onMouseEnter={() => setSelectedCard(index)}
                className="cursor-pointer"
              >
                <div
                  className={`border w-full ${selectedCard === index ? "border-primary" : "border-grey-dark opacity-[0.2]"} `}
                ></div>
                <div
                  className={`flex justify-center items-center w-[48px] h-[48px] ${selectedCard === index ? "bg-pink" : "bg-white-dark"} mt-[32px] rounded-[12px]`}
                >
                  <img
                    src={selectedCard === index ? card.srcHover : card.src}
                    className="w-[27px] h-[27px]"
                    alt="Card"
                  />
                </div>
                <h2
                  className="text-black f-f-b-g text-2xl leading-[36px] mt-[20px]"
                  dangerouslySetInnerHTML={{ __html: card.heading }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[20px] sm:mt-[64px]">
        {cardsData
          .filter((card, index) => selectedCard === index)
          .map((card, index) => (
            <img
              src={card.cardimg}
              className="w-full h-auto  mx-auto "
              key={index}
            />
          ))}
      </div>
    </div>
  );
}
