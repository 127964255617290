import React, { useEffect, useState } from "react";

type PROPS_TYPE = {
  onClose: () => void;
  show: boolean;
  width: string | number;
  className?: string;
};

function stopClickingThrough(e: React.MouseEvent) {
  //stopping the inner elements' onClick from being triggered if an overlapping element is clicked
  //this is done to ensure that the modal closes when clicked on the empty space but stopping the propagation and doing nothing when clicking on the modal body
  e.stopPropagation();
}
export function NavModal(props: React.PropsWithChildren<PROPS_TYPE>) {
  const [show, setShow] = useState(props.show); //visibility of modal
  useEffect(() => {
    if (!props.show)
      //we want to wait 350ms for the closing animation to be completed before turning off the display
      setTimeout(() => setShow(false), 350);
    //no wait needed on opening
    else setShow(true);
  }, [props.show]);
  if (!show) return null; //no display if modal closed

  //modal open
  return (
    <div
      onClick={props.onClose}
      className={
        "w-full min-h-full top-0 left-0 z-50 fixed overflow-auto bg-[rgba(0,0,0,0.4)]"
      }
    >
      <div
        style={{ width: props.width }}
        tabIndex={0}
        onClick={stopClickingThrough}
        className={
          "bg-white !w-[80%] md:!w-auto min-h-full absolute fade-in-animation " +
          (props.show ? "fade-in-animation" : "fade-out-animation")
        }
      >
        {props.children}
      </div>
    </div>
  );
}
export default function Modal(props: React.PropsWithChildren<PROPS_TYPE>) {
  const [show, setShow] = useState(props.show); //visibility of modal
  useEffect(() => {
    if (!props.show)
      //we want to wait 350ms for the closing animation to be completed before turning off the display
      setTimeout(() => setShow(false), 350);
    //no wait needed on opening
    else setShow(true);
  }, [props.show]);
  //modal open
  return (
    <div
      style={{ display: show ? "block" : "none" }}
      onClick={props.onClose}
      className={
        "w-full min-h-full top-0 left-0 z-50 fixed overflow-auto bg-[rgba(0,0,0,0.4)] " +
        props.className
      }
    >
      <div
        // style={{ maxWidth: 618 }}
        tabIndex={0}
        onClick={stopClickingThrough}
        className={
          " mx-auto h-[95vh] md:h-[100vh] flex md:items-center mx-3 sm:mx-auto  mt-5 md:mt-0 fade-in-animation2 md:overflow-visible" +
          (props.show ? "fade-in-animation2" : "fade-out-animation2")
        }
      >
        <div
          className={
            "rounded-[6px] md:rounded-[6px] md:overflow-auto bg-white mx-auto "
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
