import React from "react";

import Rectangle from "../../assets/images/insight7.svg";
import doctoer from "../../assets/images/insight8.svg";
import laptp from "../../assets/images/insight9.svg";
export default function insights() {
  return (
    <div className=" pt-[76px]   ">
      <div className=" w-11/12 2xl:max-w-[1170px] 3xl:max-w-[1500px] mx-auto ">
        <div className="grid grid-cols-12 gap-4 items-center ">
          <div className=" col-span-12 lg:col-span-8 text-center lg:text-left">
            <h2 className=" text-black f-f-b-g text-4xl sm:text-7xl ">
              Catapult sales motion with industry insights
            </h2>
          </div>
          <div className=" col-span-12  lg:col-span-4 text-center lg:text-right ">
            <button
              className="  bg-primary w-[194px] h-[64px] rounded-[48px] f-f-b text-lg text-white "
              onclick={() => window.open("https://blog.saaspay.com/")}
            >
              Read Blogs
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 2xl:gap-[30px] mt-[60px] ">
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/extended-payback-periods-and-why-sellers-need-to-offer-this-in-2024">
              <img src={Rectangle} className=" w-full " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                Extended Payback Periods & Why Sellers Need To Offer This in
                2024
              </h5>
            </a>
          </div>
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/a-rep-s-guide-to-using-saaspay-to-close-more-saas-deals">
              <img src={doctoer} className=" w-full " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                A Rep's Guide to Using SaaSPay to Close More SaaS Deals
              </h5>
            </a>
          </div>
          <div className=" col-span-12 md:col-span-6 xl:col-span-4  text-center md:text-left ">
            <a href="https://blog.saaspay.com/saas-competitive-advantage">
              <img src={laptp} className=" w-full " alt="" />
              <h5 className=" text-black text-xl f-f-b-g mt-2 xl:leading-[34px] mt-[30px] ">
                3 Step Framework to Workout SaaS Competitive Advantage
              </h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
